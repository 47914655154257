import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box, Container, Grid, Typography } from '@material-ui/core';

import { graphql, useStaticQuery } from 'gatsby';
import { Button } from 'gatsby-theme-material-ui';
import SEO from '../components/layout/seo';
import FeatureSection from '../ui/FeatureSection';
import Quote from '../ui/Quote';
import CompanyCard from '../components/company/CompanyCard';

const HeroStyled = styled.div`
  height: ${({ theme }) =>
    `calc(100vh - ${theme.mixins.toolbar.minHeight}px - (90vw / 9) - 2rem)`};

  .container {
    height: 100%;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  .text {
    display: flex;
    padding: 3rem;
  }
`;

const IndexPage = () => {
  const { datoCmsHome, allDatoCmsCompany } = useStaticQuery(graphql`
    {
      datoCmsHome {
        featuredText
        lead
        featuredImage {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        aboutExcerpt
        aboutImage {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      datoCmsAbout {
        featuredImage {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      allDatoCmsCompany(
        filter: { incompleteData: { ne: true } }
        sort: { fields: companyName }
      ) {
        nodes {
          id
          companyName
          companySlug
          companyLogo {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Alphanorth. O primeiro distrito industrial privado de Piracicaba" />
      <HeroStyled>
        <Grid container className="container" alignItems="center">
          <Grid item sm={6} className="text">
            <div>
              <Typography variant="h2" paragraph>
                {datoCmsHome.featuredText}
              </Typography>
              <Typography paragraph>{datoCmsHome.lead}</Typography>
              <Button
                to="/empresas"
                variant="contained"
                color="primary"
                disableElevation
              >
                Conheça as empresas
              </Button>
            </div>
          </Grid>
          <Grid item sm={6} style={{ height: '100%' }}>
            <Img fluid={datoCmsHome.featuredImage.fluid} alt="" />
          </Grid>
        </Grid>
      </HeroStyled>

      <Container>
        <Quote />
      </Container>

      <Container>
        <Grid container spacing={2}>
          {allDatoCmsCompany.nodes.map((company) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
              <CompanyCard node={company} />
            </Grid>
          ))}
        </Grid>
        <Box
          marginY={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            to="/empresas"
            variant="contained"
            color="primary"
            size="large"
          >
            Conheça nossas empresas
          </Button>
        </Box>
      </Container>

      <Container style={{ margin: '5rem auto' }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <Img fluid={datoCmsHome.aboutImage.fluid} alt="" />
          </Grid>
          <Grid item xs={12} md={5}>
            <FeatureSection
              lead="Quem somos"
              title="A Alphanorth"
              excerpt={datoCmsHome.aboutExcerpt}
              destination="/sobre"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default IndexPage;
