import { Divider, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const QuoteStyled = styled.div`
  max-width: 600px;
  margin: 5rem auto;

  .MuiDivider-root {
    max-width: 250px;
    height: 7px;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  .text {
    margin: 0 !important;
    padding: 2rem 1rem;
    color: #525252de;
    font-size: 1.5rem;
  }
`;

const Quote = ({ align = 'center' }) => (
  <QuoteStyled>
    <Divider />
    <Typography align={align} className="text">
      Nosso trabalho é dedicar parte do nosso tempo a representação coletiva ou
      individual dos Associados. Buscamos encaminhar e resolver os problemas
      relacionados com a instalação e funcionamento das nossas empresas,
      terrenos, comércio e bem comum.
    </Typography>
    <Divider />
  </QuoteStyled>
);

export default Quote;
