import React from 'react';
import styled from 'styled-components';
import { Button } from 'gatsby-theme-material-ui';
import { Box, Typography } from '@material-ui/core';

const FeatureStyled = styled.div`
  padding: 2rem;

  .lead {
    color: gray;
    font-weight: bolder;
    font-family: 'arboria';
    text-transform: uppercase;
  }
`;

const FeatureSection = ({ title, excerpt, lead, destination }) => (
  <FeatureStyled>
    <Typography className="lead">{lead}</Typography>
    <Typography variant="h3" component="h2" paragraph>
      {title}
    </Typography>
    <Typography paragraph>{excerpt}</Typography>
    <Button
      to={destination}
      variant="contained"
      color="primary"
      disableElevation
    >
      Conheça mais
    </Button>
  </FeatureStyled>
);

export default FeatureSection;
